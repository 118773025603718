import { graphql, Link, useStaticQuery } from "gatsby"
import React from "react"
import styles from "./style.module.scss"

const BlogsList = () => {
  const { allMarkdownRemark } = useStaticQuery(graphql`
  query {
    allMarkdownRemark(
      filter: { frontmatter: { category: { eq: "blog" }}}
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`)
  const posts = allMarkdownRemark.edges
  return (
    posts.map(({ node }) => {
      const title = node.frontmatter.title || node.fields.slug
      return (
        <article className={styles.container} key={node.fields.slug}>
          <header>
            <Link className={styles.blogTitle} to={node.fields.slug}>
              {title}
            </Link>
            <br/>
            <small className={styles.date}>{node.frontmatter.date}</small>
          </header>
          <section className={styles.content}>
            <div
              dangerouslySetInnerHTML={{
                __html: node.frontmatter.description || node.excerpt
              }}
            />
          </section>
        </article>
      )
    })
  )
}

export default BlogsList
