import React from "react"

import Layout from "../components/layout"
import BlogsList from "../components/blogslist"
import SEO from "../components/seo"

const IndexPage = () => {


  return (
    <Layout>
      <SEO title="Home"/>
      <BlogsList/>
    </Layout>
  )
}

export default IndexPage
